<template>
  <section>
    <div
      class="
        bg-primary
        position-absolute
        h-100
        top-0
        left-0
        zindex-100
        col-lg-6 col-xl-6
        zindex-100
        d-none d-lg-flex
        flex-column
        justify-content-end
      "
      data-bg-size="cover"
      data-bg-position="center"
    >
      <img src="@/assets/login-background.jpg" alt="Image" class="img-as-bg" />
      <div class="row position-relative zindex-110 p-5">
        <div class="col-md-8 text-center mx-auto">
          <el-card shadow="always">
            <span class="badge badge-warning badge-pill">News</span>
            <h5 class="h5 mt-3">Your FarePlay Client portal is here</h5>
            <p class="opacity-8">
              Everything you need to manage your FarePlay subscription.
            </p>
          </el-card>
        </div>
      </div>
    </div>
    <div class="container-fluid d-flex flex-column">
      <div
        class="
          row
          align-items-center
          justify-content-center justify-content-lg-end
          min-vh-100
        "
      >
        <div class="col-sm-7 col-lg-6 col-xl-6 py-6 py-md-0">
          <div class="row justify-content-center">
            <div class="card shadow zindex-100 mb-0">
              <div class="card-body px-md-5 py-5">
                <div class="mb-5">
                  <h6 class="h3">Welcome back!</h6>
                  <p class="text-muted mb-0">
                    Sign in to your account to continue.
                  </p>
                </div>
                <span class="clearfix"></span>
                <el-form
                  :model="form"
                  :rules="rules"
                  ref="form"
                  @submit.prevent="submitForm()"
                >
                  <el-form-item label="Email address" prop="email">
                    <el-input
                      v-model.trim="form.email"
                      placeholder="name@example.com"
                      type="email"
                    >
                      <template #prefix>
                        <el-icon><user /></el-icon>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Password" prop="password">
                    <el-input
                      v-model="form.password"
                      placeholder="Password"
                      type="password"
                    >
                      <template #prefix
                        ><el-icon><key /></el-icon
                      ></template>
                    </el-input>
                  </el-form-item>
                  <div class="mt-4">
                    <!--<el-button
                      :loading="isLoading"
                      class="btn btn-block btn-primary"
                      type="primary"
                      @click="submitForm()"
                      >Sign in</el-button
                    >-->
                    <button
                      v-loading="isLoading"
                      class="btn btn-block btn-primary"
                      type="primary"
                      @click.prevent="submitForm()"
                    >
                      Sign in
                    </button>
                  </div>
                </el-form>
              </div>
              <div align="center" class="card-footer px-md-5">
                <router-link
                  :to="{ name: 'forgot' }"
                  href="#"
                  class="small font-weight-bold"
                  >Forgot password</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { User, Key } from "@element-plus/icons-vue";

export default {
  name: "Login",
  components: {
    User,
    Key,
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
          {
            min: 4,
            message: "Email length should be at least 5 characters",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "Password is required", trigger: "blur" },
          {
            min: 5,
            message: "Password length should be at least 5 characters",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("auth/login", {
              email: this.form.email,
              password: this.form.password,
            })
            .then(() => {
              this.isLoading = false;
              this.$router.push("/");
              this.$message.success("Logged in successful");
            })
            .catch((error) => {
              this.isLoading = false;
              this.$message.error(error);
              console.error(error);
            });
        } else {
          this.$message.error("Username or password is invalid");
          this.isLoading = false;
        }
      });
    },
  },
  mounted() {
    if (this.$route.params && this.$route.params.logout) {
      this.$message.success("You have successfully logged out");
    }
  },
};
</script>

<style scoped>
</style>
