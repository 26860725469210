import { createStore } from 'vuex'
import auth from './auth'
import storage from './storage'
import adhoc from './adhoc'
import articles from './articles'

export default createStore({
  state: {
    dashboardURLs: {
      mpl: 'https://datastudio.google.com/u/0/reporting/1cc94d5e-ea98-460e-9149-f32e17bbe2cb/page/B9sxB',
      mst: 'https://datastudio.google.com/u/0/reporting/4e8f7672-2c56-4f62-8c34-4f994e51c848/page/B9sxB',
    },
  },
  getters: {
    dashboardURL: (state, rootGetters) => {
      return rootGetters['auth/userProfile'] &&
        'profile' in rootGetters['auth/userProfile'] &&
        'company' in rootGetters['auth/userProfile'].profile &&
        rootGetters['auth/userProfile'].profile.company in state.dashboardURLs
        ? state.dashboardURLs[rootGetters['auth/userProfile'].profile.company]
        : false
    },
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    storage,
    adhoc,
    articles,
  },
})
