import { parseISO, format, formatDuration, minutesToHours } from 'date-fns'
import { Schedule } from '@/rschedule'
import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Amsterdam')

export function formatISODateTime(datetime, formatString = 'dd MMM yy') {
  if (!datetime) return null
  return format(parseISO(datetime), formatString)
}

export function formatFirestoreDateTime(datetime, formatString = 'dd MMM yy') {
  if (!datetime) return null
  return format(datetime.seconds * 1000, formatString)
}

export function convertMinutesToHumanTime(
  minutes,
  format = ['hours', 'minutes']
) {
  if (minutes === 0) return null
  let hours = 0
  if (minutes > 60) {
    hours = minutesToHours(minutes)
    minutes -= hours * 60
  }
  return formatDuration({ hours: hours, minutes: minutes }, { format: format })
}

export function calculateSchedule(scheduleParameters) {
  const options = scheduleParameters
  const typeRepeatEvery = options.typeRepeatEvery
  const numberRepeatEvery = options.numberRepeatEvery
  const typeEndsOn = options.typeEndsOn
  const occurrencesEndsOn = options.occurrencesEndsOn
  const dayOfMonthToRepeat = options.dayOfMonthToRepeat

  let dayInWeekToRepeat = []
  for (const dayInWeek of options.dayInWeekToRepeat) {
    if (dayInWeek === 'Mon') dayInWeekToRepeat.push('MO')
    else if (dayInWeek === 'Tue') dayInWeekToRepeat.push('TU')
    else if (dayInWeek === 'Wed') dayInWeekToRepeat.push('WE')
    else if (dayInWeek === 'Thu') dayInWeekToRepeat.push('TH')
    else if (dayInWeek === 'Fri') dayInWeekToRepeat.push('FR')
    else if (dayInWeek === 'Sat') dayInWeekToRepeat.push('SA')
    else if (dayInWeek === 'Sun') dayInWeekToRepeat.push('SU')
  }

  const dateTimeStartsOn = moment(
    `${options.dateStartsOn} ${options.timeStartsOn}`
  )
  const dateEndsOn = moment(`${options.dateEndsOn} ${options.timeStartsOn}`)

  let rrules = {
    interval: numberRepeatEvery,
    start: dateTimeStartsOn,
  }
  let take = Infinity
  if (typeRepeatEvery === 'day') {
    rrules['frequency'] = 'DAILY'
  } else if (typeRepeatEvery === 'week') {
    if (dayInWeekToRepeat.length === 0) return false
    rrules['frequency'] = 'WEEKLY'
    rrules['byDayOfWeek'] = dayInWeekToRepeat
  } else if (typeRepeatEvery === 'month') {
    rrules['frequency'] = 'MONTHLY'
    rrules['byDayOfMonth'] = dayOfMonthToRepeat
  } else return false

  if (typeEndsOn === 'date') {
    rrules['end'] = dateEndsOn
  } else if (typeEndsOn === 'occurences') {
    take = occurrencesEndsOn
  } else return false

  const schedule = new Schedule({
    rrules: [rrules],
    timezone: 'Europe/Amsterdam',
  })
  return schedule.occurrences({ take })
}
