import { db, storage, firestore } from "@/firebase"
import { ElMessage } from "element-plus"

export default {
  namespaced: true,
  state() {
    return {
      files: null,
      totalFilesInStorage: 0,
      minCreatedAt: null,
      maxCreatedAt: null,
      lastUpdate: null,
    }
  },
  getters: {
    files: (state) => {
      return state.files
    },
    lastUpdate: (state) => {
      return state.lastUpdate
    },
    totalFilesInStorage: (state) => {
      return state.totalFilesInStorage
    },
    minCreatedAt: (state) => {
      return state.minCreatedAt
    },
    maxCreatedAt: (state) => {
      return state.maxCreatedAt
    },
  },
  mutations: {
    setFiles(state, files) {
      state.files = files
    },
    setLastUpdateNow(state) {
      state.lastUpdate = new Date().toISOString()
    },
    setTotalFilesInStorage(state, total) {
      state.totalFilesInStorage = total
    },
    setMinCreatedAt(state, minCreatedAt) {
      state.minCreatedAt = minCreatedAt
    },
    setMaxCreatedAt(state, maxCreatedAt) {
      state.maxCreatedAt = maxCreatedAt
    },
  },
  actions: {
    getFiles({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("storage")
            .onSnapshot(
              (snapshot) => {
                if (state.files !== null) {
                  snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                      const fileData = change.doc.data()
                      ElMessage.success({
                        message: `The results of your job ${fileData["job_id"]} have been added to your Files section`,
                        duration: 0,
                        showClose: true,
                      })
                    }
                  })
                }

                commit("setLastUpdateNow")
                let files = []
                snapshot.forEach((doc) => {
                  let fileData = doc.data()
                  fileData.id = doc.id
                  if (doc.id !== "stats") {
                    files.push(fileData)
                  } else {
                    commit("setTotalFilesInStorage", fileData["totalFiles"])
                    commit("setMinCreatedAt", fileData["minCreatedAt"])
                    commit("setMaxCreatedAt", fileData["maxCreatedAt"])
                  }
                })
                files.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                commit("setFiles", files)
                resolve(true)
              },
              (error) => {
                console.error(error)
                commit("setFiles", null)
                reject(error)
              }
            )
        } catch (error) {
          console.error(error)
        }
      })
    },
    downloadFile({ rootGetters }, file) {
      const fileRef = storage.refFromURL(
        "gs://fareplay_customers/" + file.reference
      )
      fileRef
        .getDownloadURL()
        .then(async (url) => {
          const userProfile = rootGetters["auth/userProfile"]
          const userID = rootGetters["auth/userID"]
          const company = userProfile.profile.company
          await db
            .collection("customers")
            .doc(company)
            .collection("storage")
            .doc(file.id)
            .update({
              stats: firestore.FieldValue.arrayUnion({
                userID,
                dateTime: new Date(),
              }),
            })

          window.open(url)
        })
        .catch(function(error) {
          console.error(error)
        })
    },
  },
}
