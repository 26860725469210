import router from '../router'
import {
  firebase,
  auth,
  usersCollection,
  companiesCollection,
} from '../firebase'
import { functions } from '@/firebase'
import { ElMessage } from 'element-plus'

import bcrypt from 'bcryptjs'
const saltRounds = 10

export default {
  namespaced: true,
  state() {
    return {
      user: {},
      userProfile: {},
      companyProfile: {},
      isLoggedIn: false,
    }
  },
  getters: {
    isLoggedIn: (state) => {
      return state.isLoggedIn
    },
    user: (state) => {
      return state.user
    },
    userProfile: (state) => {
      return state.userProfile
    },
    companyProfile: (state) => {
      return state.companyProfile
    },
    userID: (state) => {
      return state.user.uid
    },
    isAdmin: (state) => {
      return 'isAdmin' in state.userProfile && state.userProfile.isAdmin
    },
  },
  mutations: {
    setUser(state, val) {
      state.user = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setCompanyProfile(state, val) {
      state.companyProfile = val
    },
    setIsLoggedIn(state, val) {
      state.isLoggedIn = val
    },
  },
  actions: {
    sendForgottenPasswordEmail(_, email) {
      return new Promise((resolve, reject) => {
        try {
          auth
            .sendPasswordResetEmail(email)
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              console.error('Error resetting password:', error)
              reject(error)
            })
        } catch (err) {
          console.error(err)
          reject(err)
        }
      })
    },

    resetPassword({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        try {
          auth
            .verifyPasswordResetCode(payload.oobCode)
            .then((email) => {
              auth
                .confirmPasswordReset(payload.oobCode, payload.password)
                .then(() => {
                  auth
                    .signInWithEmailAndPassword(email, payload.password)
                    .then(() => {
                      dispatch('deleteFTPAccess').then(() => {
                        dispatch('logout').then(() => {
                          resolve(true)
                        })
                      })
                    })
                    .catch((error) => {
                      console.error(error)
                      reject(error)
                    })
                })
                .catch((error) => {
                  console.error(error)
                  reject(error)
                })
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        } catch (error) {
          console.error(error)
          reject(error)
        }
      })
    },

    login({ /*dispatch,*/ commit }, form) {
      return new Promise((resolve, reject) => {
        try {
          auth
            .signInWithEmailAndPassword(form.email, form.password)
            .then(({ user }) => {
              if (user) {
                commit('setUser', user)
                /*dispatch('fetchUserProfile').then(() => {
                  resolve(true)
                })*/
                resolve(true)
              } else {
                reject('Username or password not correct')
              }
            })
            .catch((error) => {
              console.error('Error logging in:', error)
              reject('Username or password not correct')
            })
        } catch (err) {
          console.error(err)
          reject('Username or password not correct')
        }
      })
    },
    register({ dispatch, commit }, form) {
      return new Promise((resolve, reject) => {
        try {
          auth
            .createUserWithEmailAndPassword(form.email, form.password)
            .then(({ user }) => {
              if (user) {
                usersCollection
                  .doc(user.uid)
                  .set({
                    profile: {
                      firstname: form.firstname,
                      lastname: form.lastname,
                    },
                    createdAt: new Date(),
                  })
                  .then(async () => {
                    commit('setUser', user)
                    await Promise.all(
                      dispatch('fetchUserProfile'),
                      dispatch('fetchCompanyProfile')
                    )
                    /*dispatch("fetchUserProfile").then(() => {
                      resolve(true);
                    });*/
                    resolve(true)
                  })
                  .catch((error) => {
                    console.error('Error getting user profile:', error)
                    reject('We could not register your account')
                  })
              } else {
                reject('We could not register your account')
              }
            })
            .catch((error) => {
              console.error('Error creating user:', error)
              reject('We could not register your account')
            })
        } catch (err) {
          console.error(err)
          reject('We could not register your account')
        }
      })
    },
    logout({ commit }) {
      auth.signOut().then(() => {
        commit('setUser', {})
        commit('setUserProfile', {})
        commit('setCompanyProfile', {})
        router.replace({ name: 'login', params: { logout: true } })
      })
    },
    setFTPAccess(/*{ dispatch }*/ _, { activate, password }) {
      return new Promise((resolve, reject) => {
        try {
          const salt = bcrypt.genSaltSync(saltRounds)
          const hash = bcrypt.hashSync(password, salt)
          const setFTPAccess = functions.httpsCallable('portal_setFTPAccess')
          setFTPAccess({ activate, hash })
            .then((response) => {
              console.log(response)
              //dispatch('fetchUserProfile')
              resolve(true)
            })
            .catch((error) => {
              console.error(error)
              ElMessage({
                showClose: true,
                message: error,
                type: 'error',
              })
            })
            .catch((error) => console.error(error))
        } catch (error) {
          console.error('Error setting FTP access: ', error)
          ElMessage({
            showClose: true,
            message: error,
            type: 'error',
          })
          reject(error)
        }
      })
    },
    deleteFTPAccess(/*{ dispatch }*/) {
      return new Promise((resolve, reject) => {
        try {
          const deleteFTPAccess = functions.httpsCallable(
            'portal_deleteFTPAccess'
          )
          deleteFTPAccess()
            .then((response) => {
              console.log(response)
              // dispatch('fetchUserProfile')
              resolve(true)
            })
            .catch((error) => {
              console.error(error)
              ElMessage({
                showClose: true,
                message: error,
                type: 'error',
              })
            })
            .catch((error) => console.error(error))
        } catch (error) {
          console.error('Error deleting FTP access: ', error)
          ElMessage({
            showClose: true,
            message: error,
            type: 'error',
          })
          reject(error)
        }
      })
    },
    verifyPassword({ state }, password) {
      return new Promise((resolve, reject) => {
        try {
          const credential = firebase.auth.EmailAuthProvider.credential(
            state.user.email,
            password
          )

          firebase
            .auth()
            .currentUser.reauthenticateWithCredential(credential)
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
        } catch (error) {
          reject(error)
        }
      })
    },
    fetchUserProfile({ commit, state }) {
      /*return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .get()
          .then((userProfile) => {
            commit('setUserProfile', userProfile.data())
            resolve(true)
          })
          .catch((error) => {
            console.error('Error getting user profile:', error)
            reject(error)
          })
      })*/
      return new Promise((resolve, reject) => {
        try {
          usersCollection.doc(state.user.uid).onSnapshot(
            (userProfile) => {
              commit('setUserProfile', userProfile.data())
              resolve(true)
            },
            (error) => {
              console.error(error)
              reject(error)
            }
          )
        } catch (error) {
          console.error('Error getting user profile: ', error)
          ElMessage({
            showClose: true,
            message: error,
            type: 'error',
          })
          reject(error)
        }
      })
    },
    fetchCompanyProfile({ commit, state }) {
      return new Promise((resolve, reject) => {
        if ('company' in state.userProfile.profile === false) {
          reject('No company in userProfile')
          return false
        }
        companiesCollection
          .doc(state.userProfile.profile.company)
          .get()
          .then((companyProfile) => {
            commit('setCompanyProfile', companyProfile.data())
            resolve(true)
          })
          .catch((error) => {
            console.error('Error getting company profile:', error)
            reject(error)
          })
      })
    },
    updateProfile({ state /*, dispatch*/ }, form) {
      console.log(form)
      const company = state.userProfile.profile.company
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .update({
            profile: {
              firstname: form.firstname,
              lastname: form.lastname,
              company,
            },
          })
          .then(() => {
            //dispatch('fetchUserProfile')
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setIsLoggedIn({ commit }, isLoggedIn) {
      commit('setIsLoggedIn', isLoggedIn)
    },
    setUser({ commit }, user) {
      commit('setUser', user)
    },
  },
}
