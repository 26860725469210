import firebase from 'firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import '@firebase/storage'
import '@firebase/functions'
import '@firebase/analytics'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyChKaHZnz0bLnKEMRyf0z5oRU66ThTNiwM',
  authDomain: 'lucid-sol-219813.firebaseapp.com',
  projectId: 'lucid-sol-219813',
  storageBucket: 'lucid-sol-219813.appspot.com',
  messagingSenderId: '61477042971',
  appId: '1:61477042971:web:e8939522b5b79a467731be',
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.analytics().logEvent('test_event_portal')

// utils
const db = firebase.firestore()
const firestore = firebase.firestore
// if (location.hostname === "localhost") {
//     db.useEmulator("localhost", 8081);
// }
const auth = firebase.auth()
const storage = firebase.storage()
const fareplay_articles_storage = firebase
  .app()
  .storage('gs://fareplay_articles')
const functions = firebase.app().functions('europe-west3')

// collection references
const usersCollection = db.collection('users')
const companiesCollection = db.collection('customers')

// export utils/refs
export {
  firebase,
  firestore,
  db,
  auth,
  storage,
  fareplay_articles_storage,
  functions,
  usersCollection,
  companiesCollection,
}
