import { firebase, db, functions } from "@/firebase"
import { calculateSchedule } from "@/common"
import { ElMessage, ElNotification } from "element-plus"

// TODO: check if job already in progress
export default {
  namespaced: true,
  state() {
    return {
      jobs: null,
      receipts: null,
      jobRoutes: null,
      currentJobRoutes: null,
      progressTotalCounter: 0,
      progressCurrentCounter: 0,
      cancelUpload: false,
      flightConnectionsAirlines: [],
    }
  },
  getters: {
    jobs: (state) => {
      return state.jobs
    },
    jobRoutes: (state) => {
      return state.jobRoutes
    },
    progressTotalCounter: (state) => {
      return state.progressTotalCounter
    },
    progressCurrentCounter: (state) => {
      return state.progressCurrentCounter
    },
    isCancelUpload: (state) => {
      return state.cancelUpload
    },
    receipts: (state) => {
      return state.receipts
    },
    flightConnectionsAirlines: (state) => {
      return state.flightConnectionsAirlines
    },
  },
  mutations: {
    setJobs(state, jobs) {
      state.jobs = jobs
    },
    setJobRoutes(state, jobRoutes) {
      state.jobRoutes = jobRoutes
    },
    setCurrentJobRoutes(state, jobID) {
      state.currentJobRoutes = jobID
    },
    setProgressTotalCounter(state, counter) {
      state.progressTotalCounter = counter
    },
    setProgressCurrentCounter(state, counter) {
      state.progressCurrentCounter = counter
    },
    setCancelUpload(state, boolean) {
      state.cancelUpload = boolean
    },
    setReceipts(state, receipts) {
      state.receipts = receipts
    },
    setFlightConnectionsAirlines(state, airlines) {
      state.flightConnectionsAirlines = airlines
    },
  },
  actions: {
    resetProgress({ commit }) {
      commit("setCancelUpload", false)
      commit("setProgressTotalCounter", 0)
      commit("setProgressCurrentCounter", 0)
    },
    getJobs({ commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .orderBy("created_at", "desc")
            .onSnapshot(
              (querySnapshot) => {
                let jobs = []
                querySnapshot.forEach((doc) => {
                  let jobData = doc.data()
                  jobData.id = doc.id
                  jobs.push(jobData)
                })
                commit("setJobs", jobs)
                resolve(true)
              },
              (error) => {
                console.error(error)
                reject(error)
              }
            )
        } catch (error) {
          console.error("Error getting jobs: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    getJob({ rootGetters }, jobID) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .get()
            .then((job) => {
              if (job.data()) {
                let jobData = job.data()
                jobData.id = job.id
                resolve(jobData)
              } else {
                reject("Job not found")
              }
            })
            .catch((error) => {
              console.error("Error getting job:", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error getting job: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    getJobRoutes({ commit, state, rootGetters }, jobID) {
      return new Promise((resolve, reject) => {
        if (state.currentJobRoutes === jobID) {
          resolve(true)
          return
        }
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .collection("routes")
            .onSnapshot(
              (querySnapshot) => {
                let routes = []
                querySnapshot.forEach((doc) => {
                  let routeData = doc.data()
                  routeData.id = doc.id
                  routes.push(routeData)
                })
                commit("setJobRoutes", routes)
                commit("setCurrentJobRoutes", jobID)
                resolve(true)
              },
              (error) => {
                console.error(error)
                reject(error)
              }
            )
        } catch (error) {
          console.error("Error getting job routes: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    getJobRoute({ rootGetters }, { jobID, routeID }) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .collection("routes")
            .doc(routeID)
            .get()
            .then((route) => {
              if (route.data()) {
                let routeData = route.data()
                routeData.id = route.id
                resolve(routeData)
              } else {
                reject("Route not found")
              }
            })
            .catch((error) => {
              console.error("Error getting route:", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error getting route: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    addJob({ rootGetters }, jobName) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .add({
              name: jobName,
              routes: 0,
              daysToRetrieve: 0,
              created_at: firebase.firestore.FieldValue.serverTimestamp(),
              status: "complete",
            })
            .then((docRef) => {
              resolve(docRef.id)
            })
            .catch((error) => {
              console.error("Error writing document: ", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error writing document: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    copyJob({ rootGetters }, { jobName, jobID }) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .add({
              name: jobName,
              routes: 0,
              daysToRetrieve: 0,
              created_at: firebase.firestore.FieldValue.serverTimestamp(),
              isCopying: true,
            })
            .then((docRef) => {
              console.log("job copied (1/2)")
              const copyJob = functions.httpsCallable("portal_copyJob")
              copyJob({ jobID, newJobID: docRef.id })
                .then(() => {
                  console.log("job copied (2/2)")
                  ElNotification({
                    title: "Schedule set up",
                    message: "Your job has been copied successfully.",
                    duration: 3500,
                    type: "success",
                    showClose: true,
                  })
                })
                .catch((error) => {
                  console.error(error)
                  ElMessage({
                    showClose: true,
                    message: error,
                    type: "error",
                  })
                })
              resolve(docRef.id)
            })
            .catch((error) => {
              console.error("Error writing document: ", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error writing document: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    removeJob({ rootGetters }, jobID) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .set({ isDeleting: true }, { merge: true })
            .then(() => {
              console.log("job removed (1/2)")
              const deleteJob = functions.httpsCallable("portal_deleteJob")
              deleteJob({ id: jobID })
                .then(() => {
                  console.log("job removed (2/2)")
                })
                .catch((error) => {
                  console.error(error)
                  ElMessage({
                    showClose: true,
                    message: error,
                    type: "error",
                  })
                })
              resolve(true)
            })
            .catch((error) => {
              console.error("Error deleting document: ", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error deleting document: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    addJobSchedule({ rootGetters }, { jobID, scheduleParameters }) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          const scheduleOccurrences = calculateSchedule(scheduleParameters)
          if (!scheduleOccurrences) {
            reject(
              "An unexpected error has occurend while processing your request"
            )
            return
          }

          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .set(
              {
                scheduler: {
                  scheduleParameters,
                  status: "pending",
                  created_at: firebase.firestore.FieldValue.serverTimestamp(),
                },
              },
              { merge: true }
            )
            .then(() => {
              const scheduleJob = functions.httpsCallable("portal_scheduleJob")
              scheduleJob({ jobID })
                .then(() => {
                  ElNotification({
                    title: "Schedule set up",
                    message: "Your schedule has been set up.",
                    duration: 3500,
                    type: "success",
                    showClose: true,
                  })
                })
                .catch((error) => {
                  ElMessage({
                    showClose: true,
                    message: error,
                    type: "error",
                  })
                })
              resolve(true)
            })
            .catch((error) => {
              console.error("Error writing document: ", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error writing document: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    removeJobSchedule({ rootGetters }, jobID) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .set({ isDeleting: true }, { merge: true })
            .then(() => {
              console.log("schedule removed (1/2)")
              const deleteSchedule = functions.httpsCallable(
                "portal_deleteSchedule"
              )
              deleteSchedule({ id: jobID })
                .then(() => {
                  console.log("schedule removed (2/2)")
                })
                .catch((error) => {
                  console.error(error)
                  ElMessage({
                    showClose: true,
                    message: error,
                    type: "error",
                  })
                })
              resolve(true)
            })
            .catch((error) => {
              console.error("Error deleting document: ", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error deleting document: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    cancelUpload({ commit }) {
      commit("setCancelUpload", true)
    },
    async addRoutes({ state, commit, dispatch }, { jobID, routes }) {
      if (routes.length === 0) return

      commit("setCancelUpload", false)
      for (const route of routes) {
        if (!state.cancelUpload) {
          route.job_id = jobID
          if ("stopsAllowed" in route === false) route.stopsAllowed = false
          if ("transportMode" in route === false) route.transportMode = "flight"
          if ("carryOnBag" in route === false) route.carryOnBag = false
          if ("additionalDays" in route === false)
            route.additionalDays = {
              45: false,
              60: false,
              90: false,
              120: false,
              150: false,
              180: false,
            }
          commit("setProgressTotalCounter", routes.length)
          await dispatch("addRoute", route)
        }
      }
    },
    addRoute({ state, commit, rootGetters }, route) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          const jobID = route.job_id
          let routeObject = Object.assign({}, route)
          delete routeObject.job_id
          routeObject.created_at =
            firebase.firestore.FieldValue.serverTimestamp()
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .collection("routes")
            .add(routeObject)
            .then((docRef) => {
              const numberOfAdditionalDays = Object.values(
                routeObject.additionalDays
              ).reduce((a, item) => a + item, 0)
              const daysToGo = routeObject.daysToGo + numberOfAdditionalDays
              db.collection("customers")
                .doc(company)
                .collection("adhoc")
                .doc(jobID)
                .set(
                  {
                    routes: firebase.firestore.FieldValue.increment(1),
                    daysToRetrieve:
                      firebase.firestore.FieldValue.increment(daysToGo),
                  },
                  { merge: true }
                )
                .then(() => {
                  commit(
                    "setProgressCurrentCounter",
                    state.progressCurrentCounter + 1
                  )
                  resolve(docRef.id)
                })
                .catch((error) => {
                  console.error("Error writing document: ", error)
                  ElMessage({
                    showClose: true,
                    message: error,
                    type: "error",
                  })
                  reject(error)
                })
            })
            .catch((error) => {
              console.error("Error writing document: ", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error writing document: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    updateRoute({ rootGetters }, { routeID, route, previousRoute }) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          const jobID = route.job_id
          let routeObject = Object.assign({}, route)
          delete routeObject.job_id
          routeObject.updated_at =
            firebase.firestore.FieldValue.serverTimestamp()
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .collection("routes")
            .doc(routeID)
            .set(routeObject, { merge: true })
            .then(() => {
              const numberOfAdditionalDays = Object.values(
                routeObject.additionalDays
              ).reduce((a, item) => a + item, 0)
              const numberOfPreviousAdditionalDays = Object.values(
                previousRoute.additionalDays
              ).reduce((a, item) => a + item, 0)
              const diffDaysToGo =
                routeObject.daysToGo +
                numberOfAdditionalDays -
                (previousRoute.daysToGo + numberOfPreviousAdditionalDays)
              if (diffDaysToGo === 0) resolve(true)
              else {
                db.collection("customers")
                  .doc(company)
                  .collection("adhoc")
                  .doc(jobID)
                  .set(
                    {
                      daysToRetrieve:
                        firebase.firestore.FieldValue.increment(diffDaysToGo),
                    },
                    { merge: true }
                  )
                  .then(() => {
                    resolve(true)
                  })
                  .catch((error) => {
                    console.error("Error writing document: ", error)
                    ElMessage({
                      showClose: true,
                      message: error,
                      type: "error",
                    })
                    reject(error)
                  })
              }
            })
            .catch((error) => {
              console.error("Error writing document: ", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error writing document: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    removeRoute({ rootGetters }, { jobID, route }) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .collection("routes")
            .doc(route.id)
            .delete()
            .then(() => {
              const numberOfAdditionalDays = Object.values(
                route.additionalDays
              ).reduce((a, item) => a + item, 0)
              const daysToGo = route.daysToGo + numberOfAdditionalDays
              db.collection("customers")
                .doc(company)
                .collection("adhoc")
                .doc(jobID)
                .set(
                  {
                    routes: firebase.firestore.FieldValue.increment(-1),
                    daysToRetrieve: firebase.firestore.FieldValue.increment(
                      daysToGo * -1
                    ),
                  },
                  { merge: true }
                )
                .then(() => {
                  resolve(true)
                })
                .catch((error) => {
                  console.error("Error deleting document: ", error)
                  ElMessage({
                    showClose: true,
                    message: error,
                    type: "error",
                  })
                  reject(error)
                })
            })
            .catch((error) => {
              console.error("Error deleting document: ", error)
              ElMessage({
                showClose: true,
                message: error,
                type: "error",
              })
              reject(error)
            })
        } catch (error) {
          console.error("Error deleting document: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    /*uploadCSV({ rootGetters }, { file, jobID, csvDelimiter }) {
            return new Promise((resolve, reject) => {
                try {
                    const userProfile = rootGetters["auth/userProfile"];
                    const company = userProfile.profile.company;

                    let reader = new FileReader();
                    reader.readAsText(file);
                    reader.onloadend = () => {
                        console.log("file uploaded (1/2)")
                        ElNotification({
                            title: "File uploaded",
                            message:
                                "Your file has been uploaded. Once processing is finished the routes will be added to your job.",
                            duration: 3500,
                            type: "success",
                            showClose: true,
                        });

                        const uploadCSVFunction = functions.httpsCallable("portal_uploadCSV");
                        uploadCSVFunction({ company, jobID, file: reader.result, csvDelimiter })
                            .then(() => {
                                console.log("file uploaded (2/2)")
                                ElNotification({
                                    title: "Routes added",
                                    message:
                                        "Your routes have been added successfully.",
                                    duration: 3500,
                                    type: "success",
                                    showClose: true,
                                });
                            })
                            .catch((error) => {
                                console.error(error);
                                ElNotification({
                                    title: "Error while processing CSV",
                                    dangerouslyUseHTMLString: true,
                                    message:
                                        "We could not process your CSV file. Please make sure you are using the preformatted CSV file. It could also be that you need to change the delimiter. You can find the file's delimiter using a text-editor like Notepad.",
                                    duration: 0,
                                    type: "error",
                                    showClose: true,
                                });
                                reject(error);
                            });
                        resolve(true);
                    };
                } catch (error) {
                    console.error("Error deleting document: ", error);
                    ElMessage({
                        showClose: true,
                        message: error,
                        type: "error",
                    });
                    reject(error);
                }
            })
        },*/
    async updateJobRoutesParameters(
      { commit, state, rootGetters },
      { jobID, updateArray, routesCopy }
    ) {
      try {
        const userProfile = rootGetters["auth/userProfile"]
        const company = userProfile.profile.company
        commit("setCancelUpload", false)
        commit("setProgressCurrentCounter", 0)
        commit("setProgressTotalCounter", routesCopy.length)

        let counter = 1
        let totalDaysToRetrieve = 0
        const batch = db.batch()
        const routes = await db
          .collection("customers")
          .doc(company)
          .collection("adhoc")
          .doc(jobID)
          .collection("routes")
          .get()
        await Promise.all(
          routes.docs.map(async (route) => {
            if (!state.cancelUpload) {
              const docRef = db
                .collection("customers")
                .doc(company)
                .collection("adhoc")
                .doc(jobID)
                .collection("routes")
                .doc(route.id)
              for (const updateObject of updateArray) {
                if ("daysToGo" in updateObject) {
                  totalDaysToRetrieve += updateObject["daysToGo"]
                }

                batch.set(
                  docRef,
                  {
                    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                    ...updateObject,
                  },
                  { merge: true }
                )
                counter++
              }
            }

            if (counter > 490) {
              await batch.commit()
              counter = 1
            }

            commit(
              "setProgressCurrentCounter",
              state.progressCurrentCounter + 1
            )
          })
        )
        await batch.commit()

        if (totalDaysToRetrieve > 0) {
          for (const routeCopy in routesCopy) {
            const numberOfAdditionalDays = Object.values(
              routesCopy[routeCopy].additionalDays
            ).reduce((a, item) => a + item, 0)
            totalDaysToRetrieve += numberOfAdditionalDays
          }

          await db
            .collection("customers")
            .doc(company)
            .collection("adhoc")
            .doc(jobID)
            .set(
              {
                daysToRetrieve: totalDaysToRetrieve,
              },
              { merge: true }
            )
        }
      } catch (error) {
        console.error("Error writing document: ", error)
        ElMessage({
          showClose: true,
          message: error,
          type: "error",
        })
        return false
      }
    },
    getReceipts({ commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        try {
          const userProfile = rootGetters["auth/userProfile"]
          const company = userProfile.profile.company
          db.collection("customers")
            .doc(company)
            .collection("adhocBilling")
            .orderBy("createdAt", "desc")
            .onSnapshot(
              (querySnapshot) => {
                let receipts = []
                querySnapshot.forEach((doc) => {
                  let receiptData = doc.data()
                  receiptData.id = doc.id
                  receipts.push(receiptData)
                })
                commit("setReceipts", receipts)
                resolve(true)
              },
              (error) => {
                console.error(error)
                reject(error)
              }
            )
        } catch (error) {
          console.error("Error getting receipts: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
    getFlightConnectionsAirlines({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          db.collection("api_parameters")
            .doc("flightConnections")
            .get()
            .then((doc) => {
              let data = doc.data()
              commit("setFlightConnectionsAirlines", data.airlines)
              resolve(true)
            })
        } catch (error) {
          console.error("Error getting airlines: ", error)
          ElMessage({
            showClose: true,
            message: error,
            type: "error",
          })
          reject(error)
        }
      })
    },
  },
}
