import { db, fareplay_articles_storage as storage } from '@/firebase'
import { parse } from 'date-fns'

const slugify = (text) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

export default {
  namespaced: true,
  state() {
    return {
      articles: [],
    }
  },
  getters: {
    articles: (state) => {
      return state.articles
    },
  },
  mutations: {
    setArticles(state, articles) {
      state.articles = articles
    },
  },
  actions: {
    getArticles({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          db.collection('articles')
            .orderBy('publish_on', 'desc')
            .onSnapshot(
              (querySnapshot) => {
                let items = []
                querySnapshot.forEach((doc) => {
                  let itemData = doc.data()
                  itemData.id = doc.id
                  items.push(itemData)
                })
                commit('setArticles', items)
                resolve(true)
              },
              (error) => {
                console.error(error)
                reject(error)
              }
            )
        } catch (error) {
          console.error(error)
        }
      })
    },
    async addArticle(_, article) {
      try {
        let thumbnailUrl = null
        let attachmentUrl = null
        let timestamp = Date.now()

        const thumbnailRef = `${timestamp}/${article.thumbnailName}.${article.thumbnailExtension}`
        const thumbnailSnapshot = await storage
          .ref(thumbnailRef)
          .put(article.thumbnail)
        thumbnailUrl = await thumbnailSnapshot.ref.getDownloadURL()

        let storageObject = {
          slug: slugify(`${article.title}-${article.publish_on}`),
          title: article.title,
          description: article.description,
          publish_on: parse(article.publish_on, 'yyyy-MM-dd', new Date()),
          thumbnail: {
            name: article.thumbnailName,
            url: thumbnailUrl,
            ref: thumbnailRef,
          },
        }

        if ('attachment' in article && article.attachment !== null) {
          const attachmentRef = `${timestamp}/${article.attachmentName}.${article.attachmentExtension}`
          const attachmentSnapshot = await storage
            .ref(attachmentRef)
            .put(article.attachment)
          attachmentUrl = await attachmentSnapshot.ref.getDownloadURL()

          storageObject['attachment'] = {
            name: article.attachmentName,
            url: attachmentUrl,
            ref: attachmentRef,
          }
        }

        const docRef = await db.collection('articles').add(storageObject)

        return docRef
      } catch (error) {
        console.error(error)
      }
    },
    async removeArticle(_, article) {
      try {
        if ('thumbnail' in article) {
          await storage.ref(article.thumbnail.ref).delete()
        }

        if ('attachment' in article) {
          await storage.ref(article.attachment.ref).delete()
        }

        await db.collection('articles').doc(article.id).delete()

        return true
      } catch (error) {
        console.error(error)
      }
    },
  },
  modules: {},
}
