import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { auth } from '../firebase'

import Login from '../views/auth/Login.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/auth',
    name: 'auth',
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/auth/Auth.vue'),
  },
  {
    path: '/0L',
    name: '0L',
    component: () => import(/* webpackChunkName: "home" */ '../views/0L.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/articles',
    name: 'articles',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Articles.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/adhoc',
    name: 'adhoc',
    redirect: '/adhoc/main',
    component: () =>
      import(/* webpackChunkName: "adhoc" */ '../views/Adhoc.vue'),
    children: [
      {
        path: 'main',
        name: 'adhocMain',
        component: () =>
          import(/* webpackChunkName: "adhoc" */ '../views/adhoc/Main.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'receipts',
        name: 'adhocReceipts',
        component: () =>
          import(/* webpackChunkName: "adhoc" */ '../views/adhoc/Receipts.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':jobID',
        name: 'adhocJob',
        component: () =>
          import(/* webpackChunkName: "adhocJob" */ '../views/adhoc/Job.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':jobID/addRoutes/:type?',
        name: 'adhocJobRoutes',
        component: () =>
          import(
            /* webpackChunkName: "adhocJob" */ '../views/adhoc/Routes.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':jobID/new',
        name: 'adhocJobRoute',
        component: () =>
          import(/* webpackChunkName: "adhocJob" */ '../views/adhoc/Route.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':jobID/:routeID',
        name: 'adhocJobRoute',
        component: () =>
          import(/* webpackChunkName: "adhocJob" */ '../views/adhoc/Route.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/generator',
    name: 'generator',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Generator.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/files',
    name: 'files',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Files.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Account.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: "forgot" */ '../views/auth/Logout.vue'),
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () =>
      import(/* webpackChunkName: "forgot" */ '../views/auth/Forgot.vue'),
  },
  {
    path: '/register',
    redirect: '/login',
  },
  {
    path: '/register/:iv/:content',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
  },
  {
    path: '/:catchAll(.*)',
    //redirect: '/page-not-found'
    redirect: '/login',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  const requiresAdmin = to.matched.some((x) => x.meta.requiresAdmin)

  if (auth.currentUser && (to.name == 'forgot' || to.name == 'register')) {
    next('/logout')
  } else if ((requiresAuth || requiresAdmin) && !auth.currentUser) {
    next('/login')
  } else if (requiresAdmin && !store.getters['auth/isAdmin']) {
    next('/')
  } else if (
    auth.currentUser &&
    to.name == 'login' //|| to.name == "forgot" || to.name == 'register'
  ) {
    next('/')
  } else {
    next()
  }
})

export default router
