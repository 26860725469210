<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #718096;
  overflow-x: visible !important;
}

button:not(.no-label) svg[class*="svg-inline--fa"] {
  margin-right: 0.5em;
}

div[role="pagination"] {
  justify-content: center;
}

#leftMenu {
  background-color: #fafbfc;
  border-right: 1px solid #e5e6e8;
}

.el-form-item {
  flex-direction: column;
}

.el-form-item .el-form-item__label {
  text-align: left;
  display: block;
}

.calendarButton {
  display: inline-block !important;
  border-radius: 4px 4px 4px 4px !important;
  border: 1px solid #dcdfe6 !important;
  background: #f5f7fa !important;
  color: #606266 !important;
  text-align: center !important;
  width: 32px !important;
  height: 32px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  padding: 0 !important;
}

.calendarButton:hover:not(.is-disabled) {
  color: #409eff;
  cursor: pointer;
}

.formChangeParameters .el-form-item__label {
  padding-bottom: 0 !important;
}

#wrapper #content-wrapper #content {
  display: flex;
  flex-flow: column;
}

#wrapper #content-wrapper #content section {
  flex: 1 1 auto;
}

.spinner-small {
  width: 1rem !important;
  height: 1rem !important;
  border-width: 0.15em !important;
}

.table-striped tr:nth-of-type(even) {
  /* background-color: rgba(0, 0, 0, 0.05); */
  background-color: #f2f2f2;
}

.table th {
  color: #152c5b;
}

a.nav-link.active {
  background-color: #ebecf0;
}

.el-dialog__body {
  word-break: normal !important;
}

section.slice {
  background-color: #fafbfe;
}

.fs-14 {
  font-size: 14px;
}

.line-through {
  text-decoration: line-through;
}

.el-badge.item > .el-badge__content {
  top: -5px;
}

.el-alert--error .el-alert__title {
  font-weight: bold;
}

.el-breadcrumb__inner.is-link {
  color: #4781d9 !important;
}

.el-notification__content {
  text-align: left !important;
}

:root {
  --aa-primary-color-rgb: 0, 138, 255 !important;
  --aa-search-input-height: 40px;
  --aa-base-unit: 15;
}

.notificationZIndex {
  z-index: 9999 !important;
}

div.aa-Panel {
  z-index: 2100;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.errorPopover {
  color: var(--el-color-error) !important;
  background-color: var(--el-color-error-light-9) !important;
}

.errorPopover .el-popper__arrow::before {
  background-color: var(--el-color-error-light-9) !important;
}

/* Define an animation behavior */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1.5s linear infinite;
}
</style>
