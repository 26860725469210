import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { auth } from './firebase'
import ElementPlus from 'element-plus'
import dayjs from 'dayjs'
dayjs.Ls.en.weekStart = 1

//import installElementPlus from './plugins/element';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faClock,
  faEquals,
  faFileCsv,
  faFilePdf,
  faFileDownload,
  faHome,
  faSignOutAlt,
  faCog,
  faCogs,
  faChartLine,
  faList,
  faSync,
  faCheck,
  faTimes,
  faSearch,
  faPlay,
  faEdit,
  faTrash,
  faArrowLeft,
  faCloudUploadAlt,
  faPlus,
  faCopy,
  faQuestion,
  faReceipt,
  faNewspaper,
  faCamera,
} from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add([
  faClock,
  faFileCsv,
  faFilePdf,
  faFileDownload,
  faHome,
  faSignOutAlt,
  faEquals,
  faCog,
  faCogs,
  faChartLine,
  faList,
  faSync,
  faCheck,
  faTimes,
  faSearch,
  faPlay,
  faEdit,
  faTrash,
  faArrowLeft,
  faCloudUploadAlt,
  faCalendarAlt,
  faPlus,
  faCopy,
  faQuestion,
  faReceipt,
  faNewspaper,
  faCamera,
])

//import "./assets/css/theme-chalk/index.css";
import 'element-plus/theme-chalk/index.css'
//import './assets/css/bootstrap.css';
import './assets/css/theme.css'
import './assets/css/sidebar.css'
import './assets/css/custom.css'

let appIsMounted = false
function mountApp() {
  if (!appIsMounted) {
    router.isReady().then(() => {
      app.mount('#app')
      appIsMounted = true
    })
  }
}

let app
auth.onAuthStateChanged((user) => {
  store.dispatch('auth/setIsLoggedIn', !!user)

  if (!app) {
    app = createApp(App)
    //installElementPlus(app)
    app
      .use(store)
      .use(router)
      .use(ElementPlus)
      .component('FontAwesomeIcon', FontAwesomeIcon)
  }

  if (user) {
    store.dispatch('auth/setUser', user).then(() => {
      store.dispatch('auth/fetchUserProfile').then(() => {
        Promise.all([
          store.dispatch('storage/getFiles'),
          //store.dispatch("storage/initFilesPaginated"),
          store.dispatch('adhoc/getJobs'),
          store.dispatch('adhoc/getReceipts'),
          store.dispatch('adhoc/getFlightConnectionsAirlines'),
          store.dispatch('auth/fetchCompanyProfile'),
        ])
          .then(() => {
            mountApp()
          })
          .catch((error) => {
            console.error(error)
          })

        if (store.getters['auth/isAdmin']) {
          store.dispatch('articles/getArticles')
        }
      })
    })
  } else {
    mountApp()
  }
})
